import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import CommunityService from "../components/CommunityService"
import SEO from "../components/SEO"

const service = ({ data }) => {
  const { datoCmsCommunityService } = data
  return (
    <Layout>
      <SEO
        title="Service"
        description="Service in the International Research Community"
      />
      <section className="service-page">
        <CommunityService title="Service" {...datoCmsCommunityService} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsCommunityService {
      description
      conferenceOrganization
      journalEditorial
      mentorship
      membership
    }
  }
`
export default service
