import React from "react"
import Title from "./Title"
import ReactMarkdown from "react-markdown"

const CommunityService = ({
  title,
  description,
  conferenceOrganization,
  journalEditorial,
  mentorship,
  membership,
}) => {
  return (
    <section className="section">
      <Title title={title} />
      <div className="section-center">
        <p>
          <ReactMarkdown source={description} />
        </p>
        <br></br>
        <br></br>
        <h3>Journal Editorial Board Member and Special Issue Guest Editor</h3>
        <p>
          <ReactMarkdown source={journalEditorial} />
        </p>
        <br></br>
        <br></br>
        <h3>Conference Organization and Reviewing</h3>
        <p>
          <ReactMarkdown source={conferenceOrganization} />
        </p>
        <br></br>
        <br></br>
        <h3>Mentorship in International Community</h3>
        <p>
          <ReactMarkdown source={mentorship} />
        </p>
        <br></br>
        <br></br>
        <h3>Memberships</h3>
        <p>
          <ReactMarkdown source={membership} />
        </p>
      </div>
    </section>
  )
}

export default CommunityService
